/* eslint react/no-unused-state: 0 */

import React, { Component, createContext } from 'react'
import fetchIntercept from 'fetch-intercept'
import get from 'lodash.get'
import store from 'store'
import PropTypes from 'prop-types'
import jwt_decode from 'jwt-decode' // eslint-disable-line camelcase
import { PUBLIC_URL } from '../App'

const STORAGE_USER = 'byzans-user'
const STORAGE_ACCESS = 'byzans-access'

export const ApplicationContext = createContext({})

const decodeToken = (token = '', path = 'exp', defaultValue = 0) => {
  const decoded = jwt_decode(token)
  // console.log(decoded)
  return get(decoded, path, defaultValue)
}

class ApplicationContextWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor() {
    super()
    this.state = {
      setAuth: this.setAuth,
      fetchData: this.fetchData,
      logOut: this.logOut,
      setError: this.setError,
      clearError: this.clearError,
      appToken: store.get(STORAGE_ACCESS) || {},
      user: store.get(STORAGE_USER) || {},
      error: null,
      isFetching: false,
    }

    this.unregister = fetchIntercept.register({
      request: async (url, config) => {
        const { appToken } = this.state
        const requestParams = {
          method: 'GET',
          headers: {},
          ...config,
        }
        if (
          config.method === 'POST'
          || config.method === 'PUT'
          || config.method === 'PATCH'
          || config.method === 'DELETE'
        ) {
          requestParams.headers.Accept = 'application/json'
          requestParams.headers['Content-Type'] = 'application/json'
        }

        let token = get(appToken, 'access', '')

        if (token && url !== '/api/admin/token/refresh/') {
          const tokenAccessExp = decodeToken(token, 'exp', 0)
          const date = new Date()
          if ((date.getTime() + 60000) >= (tokenAccessExp * 1000)) {
            const refreshToken = get(appToken, 'refresh', '')
            if (!refreshToken) {
              this.logOut()
              throw new Error()
            }
            const props = {
              method: 'POST',
              body: JSON.stringify({
                refresh: refreshToken,
              }),
            }
            const newToken = await this.fetchData('/api/admin/token/refresh/', props)
            token = get(newToken, 'access', '')
            this.storeAuth(newToken, !!store.get(STORAGE_ACCESS))
          }
          const authToken = `Bearer ${token}`
          requestParams.headers.Authorization = authToken // eslint-disable-line no-param-reassign
        }
        return [url, requestParams]
      },
      response: (response) => {
        if (response.status === 401) {
          this.logOut()
        }
        return response
      },
    })
  }

  setError = (error) => {
    this.setState({
      error,
      isFetching: false,
    })
  }

  clearError = () => {
    this.setState({
      error: null,
    })
  }

  storeAuth = (data, rememberMe) => {
    if (get(data, 'access')) {
      if (rememberMe) {
        store.set(STORAGE_ACCESS, data)
      }
      this.setState({
        appToken: data,
        isFetching: false,
      }, () => this.setCurrentUser(rememberMe))
    } else if (data.status === 400) {
      this.setError({
        code: 401,
      })
    }
  }

  fetchData = async (url, options = {}, onError) => {
    let result = {}
    try {
      const data = await fetch(`${PUBLIC_URL !== '/' ? PUBLIC_URL : ''}${url}`, options)
      if (get(data, 'status', 500) < 400) {
        result = await data.json()
      } else {
        throw data
      }
    } catch (e) {
      if (onError) {
        onError(e)
      }
    }
    return result
  }

  setAuth = async (email, password, rememberMe) => {
    this.setState({ isFetching: true })
    const params = {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    }
    const appToken = await this.fetchData('/api/admin/token/', params, this.storeAuth)
    this.storeAuth(appToken, rememberMe)
  }

  setCurrentUser = async (rememberMe = false) => {
    const { appToken } = this.state
    const token = get(appToken, 'access', '')
    if (token) {
      const userId = decodeToken(token, 'user_id', 0)
      if (userId) {
        const user = await this.fetchData(`/api/admin/user/${userId}/`)
        if (get(user, 'email')) {
          if (rememberMe) {
            store.set(STORAGE_USER, user)
          }
          this.setState({
            user,
          })
        }
      }
    }
  }

  logOut = async () => {
    store.remove(STORAGE_ACCESS)
    store.remove(STORAGE_USER)
    this.setState({
      appToken: {},
      user: {},
    })
  }

  render() {
    const { children } = this.props
    return (
      <ApplicationContext.Provider value={this.state}>
        {children}
      </ApplicationContext.Provider>
    )
  }
}

export default ApplicationContextWrapper
