import React from 'react'
import UsersContextWrapper, { UsersContext } from '../../context/usersContext'
import UsersList from './UsersList'


const Users = () => (
  <UsersContextWrapper>
    <UsersContext.Consumer>
      {props => <UsersList {...props} />}
    </UsersContext.Consumer>
  </UsersContextWrapper>
)

export default Users
