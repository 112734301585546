import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Route, Switch, withRouter } from 'react-router-dom'
import Abuses from '../abuses'
import Users from '../users'
import PageNotFound from '../pageNotFound'
import './styles.css'

const Home = ({ location, history }) => (
  <div className='home-page'>
    <Tabs
      value={location.pathname}
      onChange={(e, value) => history.push(value)}
      className='navigation-tabs'
    >
      <Tab label='Registered users' value='/' />
      <Tab label='Reported abuses' value='/abuses' />
    </Tabs>
    <div className='tabs-container'>
      <Switch>
        <Route exact path='/' component={Users} />
        <Route path='/abuses' component={Abuses} />
        <Route path='*' component={PageNotFound} />
      </Switch>
    </div>
  </div>
)

Home.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
}

export default withRouter(Home)
