import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import logo from './icon.png'
import './styles.css'

class LoginForm extends Component {
  static propTypes = {
    setAuth: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Object),
    isFetching: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    error: null,
  }

  static validateEmail = str => (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(str) // eslint-disable-line max-len
  )

  static getDerivedStateFromProps(props) {
    if (props.error) {
      return {
        password: '',
        email: '',
      }
    }
    return null
  }

  state = {
    password: '',
    email: '',
    isRememberMeActive: false,
  }

  handleInputChange = (field, value) => {
    const { clearError } = this.props
    clearError()
    this.setState({ [field]: value })
  }

  handleSubmitForm = (e) => {
    e.preventDefault()
    const { setAuth } = this.props
    const { password, email, isRememberMeActive } = this.state
    if (password && email && LoginForm.validateEmail(email)) {
      setAuth(email, password, isRememberMeActive)
    }
  }

  render() {
    const { isFetching, error } = this.props
    const {
      email,
      password,
      isRememberMeActive,
    } = this.state
    const isEmailValid = LoginForm.validateEmail(email)
    let errorMessage = ''
    let errorEmailMessage = ''

    if (email.length && !isEmailValid) {
      errorEmailMessage = 'Please, enter valid email address.'
    }

    if (error) {
      if (error.code === 401) {
        errorMessage = 'Incorrect credentials'
        errorEmailMessage = 'Incorrect credentials'
      }
      if (error.code === 403) {
        errorEmailMessage = 'Not enough permissions to access'
        errorMessage = 'Not enough permissions to access'
      }
    }

    return (
      <div className='login-page'>
        <img
          src={logo}
          alt='app-logo'
          className='app-logo'
        />
        <h5>Admin Panel</h5>
        <form
          autoComplete='off'
          onSubmit={this.handleSubmitForm}
        >
          <TextField
            required
            error={!!errorEmailMessage}
            helperText={errorEmailMessage}
            id='email'
            label='Name'
            value={email}
            onChange={e => this.handleInputChange('email', e.target.value)}
            margin='normal'
          />
          <TextField
            required
            error={!!errorMessage}
            helperText={errorMessage}
            id='password'
            label='Password'
            type='password'
            value={password}
            onChange={e => this.handleInputChange('password', e.target.value)}
            margin='normal'
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={isRememberMeActive}
                onChange={e => this.handleInputChange('isRememberMeActive', e.target.checked)}
                value='rememberMe'
              />
            )}
            label='Remember me?'
          />
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={isFetching}
          >
            {isFetching ? <CircularProgress color='secondary' size={25} /> : 'Login'}
          </Button>
        </form>
      </div>
    )
  }
}

export default LoginForm
