import React from 'react'
import get from 'lodash.get'
import ExitIcon from '@material-ui/icons/ExitToApp'
import { ApplicationContext } from '../../context/appContext'
import './styles.css'

const Header = () => (
  <ApplicationContext.Consumer>
    {({ user, logOut }) => (
      <div className='header'>
        <h3>BYZANS</h3>
        <p>{get(user, 'email', '')}</p>
        <button
          type='button'
          onClick={logOut}
        >
          <ExitIcon color='primary' />
        </button>
      </div>
    )}
  </ApplicationContext.Consumer>
)


export default Header
