/* eslint no-underscore-dangle: 0 */

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
import TablePagination from '../../ui_components/tablePagination'
import ConfirmDialog from '../../ui_components/confirmDialog'
import './styles.css'

const rows = [
  {
    id: 'full_name',
    numeric: false,
    disablePadding: false,
    label: 'Full name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
]

class UsersList extends Component {
  static propTypes = {
    setUsers: PropTypes.func.isRequired,
    removeData: PropTypes.func.isRequired,
    banUser: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    isConfirmModalOpen: false,
    selected: {},
    actionType: '',
    additionalText: '',
  }

  componentDidMount() {
    const { setUsers } = this.props
    setUsers()
  }

  openConfirmModal = (item, type) => {
    let additionalText
    switch (type) {
      case 'ban':
        additionalText = 'He/she will not be able to log into system anymore.'
        break
      case 'unban':
        additionalText = 'He/she will be able to log into the application and post content.'
        break
      default:
        additionalText = ''
        break
    }

    this.setState({
      isConfirmModalOpen: true,
      selected: item,
      actionType: type,
      additionalText: additionalText,
    })
  }

  closeConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
      selected: {},
      actionType: '',
      additionalText: '',
    })
  }

  handleDelete = async () => {
    const { removeData } = this.props
    const { selected } = this.state
    await removeData(selected.id)
    this.closeConfirmModal()
  }

  handleBan = async (isBanned) => {
    const { banUser } = this.props
    const { selected } = this.state
    await banUser(selected.id, isBanned)
    this.closeConfirmModal()
  }

  handleConfirm = async () => {
    const { actionType } = this.state
    switch (actionType) {
      case 'delete':
        await this.handleDelete()
        break
      case 'ban':
        await this.handleBan(true)
        break
      case 'unban':
        await this.handleBan(false)
        break
      default:
        break
    }
  }

  handleSearchChange = (val, name) => {
    const {
      setSearch,
      search,
      limit,
      skip,
    } = this.props
    setSearch({
      search,
      limit,
      skip,
      [name]: val,
    })
  }

  renderTableHeaderRows = () => {
    const { order, orderBy, setSort } = this.props
    return rows.map(row => (
      <TableCell
        key={row.id}
        numeric={row.numeric}
        padding={row.disablePadding ? 'none' : 'default'}
        sortDirection={orderBy === row.id ? order : false}
        className={row.id}
      >
        <Tooltip
          title='Sort'
          placement={row.numeric ? 'bottom-end' : 'bottom-start'}
          enterDelay={300}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={() => setSort(row.id)}
          >
            {row.label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    ), this)
  }

  render() {
    const {
      usersList,
      search,
      total,
      skip,
      limit,
    } = this.props
    const { isConfirmModalOpen } = this.state
    const { actionType } = this.state
    const { additionalText } = this.state

    console.log(usersList)

    return (
      <div className='users-list--page'>
        <div className='search-wrapper'>
          <TextField
            id='search-users-textfield'
            placeholder='Search by name and email'
            value={search}
            onChange={e => this.handleSearchChange(e.target.value, 'search')}
            InputProps={{
              // classes: {
              //   root: {
              //     paddingBottom: '10px',
              //   },
              // },
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position='end'
                  className={`clear-icon${search && usersList.length ? '' : ' hidden'}`}
                >
                  <IconButton
                    aria-label='Clear search'
                    onClick={() => this.handleSearchChange('', 'search')}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <span className={`no-search-result${search && !usersList.length ? '' : ' hidden'}`}>
            No user by this search
          </span>
        </div>
        <Table className='users-table'>
          <TableHead>
            <TableRow>
              {this.renderTableHeaderRows()}
              <TableCell className='list-actions' />
              <TableCell className='list-actions' />
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList.map(n => (
              <TableRow key={n.id}>
                {rows.map(row => (
                  <TableCell key={row.id}>
                    {n[row.id]}
                  </TableCell>
                ))}
                <TableCell className='user-action'>
                  <button
                    type='button'
                    onClick={() => this.openConfirmModal(n, 'delete')}
                  >
                    Delete user
                  </button>
                </TableCell>
                <TableCell className={n.is_banned ? 'user-action approve-action' : 'user-action warning-action'}>
                  <button
                    type='button'
                    onClick={() => this.openConfirmModal(n, n.is_banned ? 'unban' : 'ban')}
                  >
                    {n.is_banned ? 'Unban' : 'Ban'} user
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          handleChange={this.handleSearchChange}
          total={total}
          limit={limit}
          skip={skip}
        />
        <ConfirmDialog
          isConfirmModalOpen={isConfirmModalOpen}
          closeConfirmModal={this.closeConfirmModal}
          onSubmit={this.handleConfirm}
          actionType={actionType}
          name='user'
          additionalText={additionalText}
        />
      </div>
    )
  }
}

export default withRouter(UsersList)
