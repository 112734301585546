import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'

const modalStyles = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  width: '300px',
  // height: '150px',
  backgroundColor: 'white',
}

const textStyles = {
  display: 'flex',
  alignItems: 'center',
  // height: '95px',
  margin: '34px',
  fontSize: '17px',
  lineHeight: '1.5',
  letterSpacing: '0px',
  textAlign: 'center',
  color: '#3d4248',
}

const buttonStyles = {
  width: '50%',
  height: '54px',
  border: 'none',
  borderTop: '1px solid #7093ba',
  borderRadius: '0',
  fontSize: '17px',
  letterSpacing: '0px',
  textAlign: 'center',
  color: '#3d4248',
  cursor: 'pointer',
  outlineColor: 'none',
}

const ConfirmDialog = ({
  isConfirmModalOpen,
  closeConfirmModal,
  onSubmit,
  actionType,
  name,
  additionalText,
}) => (
  <Modal
    aria-labelledby='confirm-modal'
    aria-describedby='confirm-modal-description'
    open={isConfirmModalOpen}
    onClose={closeConfirmModal}
  >
    <div
      style={modalStyles}
    >
      <p style={textStyles}>
        {`Are you sure, you want to ${actionType} this ${name}? ${additionalText}`}
      </p>
      <button
        type='button'
        onClick={closeConfirmModal}
        style={{ ...buttonStyles, fontWeight: 500 }}
      >
        Cancel
      </button>
      <button
        type='button'
        onClick={onSubmit}
        style={buttonStyles}
      >
        Yes
      </button>
    </div>
  </Modal>
)

ConfirmDialog.propTypes = {
  isConfirmModalOpen: PropTypes.bool.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  name: PropTypes.string,
  additionalText: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  name: 'object',
  additionalText: '',
}

export default ConfirmDialog
