import React from 'react'
import { Redirect } from 'react-router-dom'
import { ApplicationContext } from '../../context/appContext'
import LoginForm from './LoginForm'


const Login = () => (
  <ApplicationContext.Consumer>
    {({ appToken, ...props }) => (
      appToken.access
        ? <Redirect to='/' />
        : (
          <LoginForm {...props} />
        )
    )}
  </ApplicationContext.Consumer>
)

export default Login
