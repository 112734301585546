/* eslint react/no-unused-state: 0 */

import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import debounce from 'lodash.debounce'
import { ApplicationContext } from './appContext'

export const AbuseContext = createContext({})

class AbuseContextWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    fetchData: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      setData: this.setData,
      removeData: this.removeData,
      dataList: [],
      setSearch: this.setSearch,
      total: 0,
      limit: 20,
      skip: 0,
      isFetching: false,
    }
    this.debouncedSetData = debounce(this.setData, 500)
  }

  setSearch = ({ limit, skip }) => {
    this.setState({
      limit,
      skip,
    }, this.debouncedSetData)
  }

  setData = async () => {
    const { fetchData } = this.props
    const { limit, skip } = this.state
    this.setState({ isFetching: true })
    const dataList = await fetchData(`/api/admin/abusing-reports/?limit=${limit}&offset=${skip}`)
    if (!get(dataList, 'data.error')) {
      this.setState({
        dataList: get(dataList, 'data', []),
        total: get(dataList, 'total', 0),
        isFetching: false,
      })
    }
  }

  removeData = async (type, id) => {
    const { fetchData } = this.props
    this.setState({ isFetching: true })
    await fetchData(`/api/admin/delete/message/${type}/${id}/`, { method: 'DELETE' })
    this.setState({ isFetching: false }, this.setData)
  }

  render() {
    const { children } = this.props
    return (
      <AbuseContext.Provider value={this.state}>
        {children}
      </AbuseContext.Provider>
    )
  }
}

const WrappedContext = ({ children }) => (
  <ApplicationContext.Consumer>
    {({ fetchData }) => (
      <AbuseContextWrapper fetchData={fetchData}>
        {children}
      </AbuseContextWrapper>
    )}
  </ApplicationContext.Consumer>
)
WrappedContext.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WrappedContext
