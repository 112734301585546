import React from 'react'
import { Router } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import deepPurple from '@material-ui/core/colors/deepPurple'
import AppRoutes from './routes'
import ApplicationContextWrapper from './context/appContext'
import './styles.css'

export const PUBLIC_URL = process.env.PUBLIC_URL || '/'
export const history = createHistory({ basename: PUBLIC_URL })

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Graphik-Regular"',
    button: {
      fontFamily: '"Graphik-Medium"',
    },
  },
  palette: {
    primary: {
      light: deepPurple[200],
      main: deepPurple[300],
      dark: deepPurple[400],
      contrastText: '#fff',
      color: deepPurple[300],
    },
    secondary: {
      light: deepPurple[200],
      main: deepPurple[300],
      dark: deepPurple[400],
      contrastText: '#fff',
      color: deepPurple[300],
    },
  },
})

const App = () => (
  <Router history={history}>
    <ApplicationContextWrapper>
      <MuiThemeProvider theme={theme}>
        <AppRoutes />
      </MuiThemeProvider>
    </ApplicationContextWrapper>
  </Router>
)

export default App
