/* eslint react/no-unused-state: 0 */

import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import debounce from 'lodash.debounce'
import { ApplicationContext } from './appContext'

export const UsersContext = createContext({})

class UsersContextWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    fetchData: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      setUsers: this.setUsers,
      removeData: this.removeData,
      banUser: this.banUser,
      usersList: [],
      order: 'asc',
      orderBy: 'full_name',
      total: 0,
      limit: 20,
      skip: 0,
      search: '',
      setSearch: this.setSearch,
      setSort: this.setSort,
      isFetching: false,
    }
    this.debouncedSetUsers = debounce(this.setUsers, 300)
  }

  setSort = (property) => {
    const { order, orderBy } = this.state
    const newState = {}
    if (property === orderBy) {
      newState.order = order === 'asc' ? 'desc' : 'asc'
    } else {
      newState.orderBy = property
    }
    this.setState(newState, this.debouncedSetUsers)
  }

  setSearch = ({ search, limit, skip }) => {
    this.setState({
      search,
      limit,
      skip,
    }, this.debouncedSetUsers)
  }

  setUsers = async () => {
    const { fetchData } = this.props
    const {
      limit,
      skip,
      search,
      order,
      orderBy,
    } = this.state
    this.setState({ isFetching: true })
    const usersList = await fetchData(
      `/api/admin/facebook-users/?limit=${limit}&offset=${skip}&order=${order}&order_by=${orderBy}${search ? `&name=${search}` : ''}` // eslint-disable-line
    )
    if (!get(usersList, 'data.error')) {
      this.setState({
        usersList: get(usersList, 'data', []),
        total: get(usersList, 'total', 0),
        isFetching: false,
      })
    }
  }

  removeData = async (id) => {
    const { fetchData } = this.props
    this.setState({ isFetching: true })
    await fetchData(`/api/admin/delete/user/${id}/`, { method: 'DELETE' })
    this.setState({ isFetching: false }, this.setUsers)
  }

  banUser = async (id, isBanned) => {
    const { fetchData } = this.props
    const props = {
      method: 'PATCH',
      body: JSON.stringify({
        is_banned: isBanned,
      }),
    }
    this.setState({ isFetching: true })
    await fetchData(`/api/admin/user/ban/${id}/`, props)
    this.setState({ isFetching: false }, this.setUsers)
  }

  render() {
    const { children } = this.props
    return (
      <UsersContext.Provider value={this.state}>
        {children}
      </UsersContext.Provider>
    )
  }
}

const WrappedContext = ({ children }) => (
  <ApplicationContext.Consumer>
    {({ fetchData }) => (
      <UsersContextWrapper fetchData={fetchData}>
        {children}
      </UsersContextWrapper>
    )}
  </ApplicationContext.Consumer>
)
WrappedContext.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WrappedContext
