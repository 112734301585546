import React from 'react'
import PropTypes from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'


const TablePaginationComponent = ({
  handleChange,
  total,
  limit,
  skip,
  rowsPerPageOptions,
}) => (
  <TablePagination
    component='div'
    count={total}
    rowsPerPage={limit}
    page={skip / limit}
    backIconButtonProps={{
      'aria-label': 'Previous Page',
    }}
    nextIconButtonProps={{
      'aria-label': 'Next Page',
    }}
    rowsPerPageOptions={rowsPerPageOptions}
    onChangePage={(event, page) => handleChange(page * limit, 'skip')}
    onChangeRowsPerPage={e => handleChange(e.target.value, 'limit')}
  />
)

TablePaginationComponent.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  handleChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
}

TablePaginationComponent.defaultProps = {
  rowsPerPageOptions: [10, 20, 50],
}

export default TablePaginationComponent
