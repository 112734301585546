import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ApplicationContext } from '../context/appContext'
import Header from '../ui_components/header'
import Login from './login'
import Home from './home'

const AppLayout = () => (
  <div className='app-layout'>
    <Header />
    <Home />
  </div>
)

const AppRouter = () => (
  <ApplicationContext.Consumer>
    {({ appToken }) => (
      <Switch>
        <Route exact path='/login' component={Login} />
        {appToken.access
          ? <Route path='*' component={AppLayout} />
          : <Redirect to='/login' />
        }
      </Switch>
    )}
  </ApplicationContext.Consumer>
)

export default AppRouter
