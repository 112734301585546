/* eslint no-underscore-dangle: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
import ConfirmDialog from '../../ui_components/confirmDialog'
import TablePagination from '../../ui_components/tablePagination'
import './styles.css'

const rows = [
  {
    id: 'content',
    label: 'Content of message',
  },
  {
    id: 'isbn13',
    label: 'ISBN-13',
  },
  {
    id: 'who_posted',
    label: 'Who posted',
  },
  {
    id: 'when_posted',
    label: 'When posted',
  },
  {
    id: 'where_posted',
    label: 'Where posted\n(Salon/Café)',
  },
  {
    id: 'quantity_of_reports',
    label: 'Quantity of reports for this message',
  },
]

class AbuseList extends Component {
  static propTypes = {
    setData: PropTypes.func.isRequired,
    removeData: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    dataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    isConfirmModalOpen: false,
    selected: {},
    isPreviewModalOpen: false,
  }

  componentDidMount() {
    const { setData } = this.props
    setData()
  }

  componentDidUpdate() {
    const elements = document.querySelectorAll('td.content')
    elements.forEach((el) => {
      if (el.clientHeight > 70) {
        el.classList.add('long')
      }
    })
  }

  renderTableHeaderRows = () => rows.map(row => (
    <TableCell
      key={row.id}
      numeric={row.numeric}
      padding={row.disablePadding ? 'none' : 'default'}
      className={row.id}
    >
      {row.label}
    </TableCell>
  ), this)

  cellRenderer = (item, row) => {
    if (row.id === 'content') {
      return (
        <>
          <p className='abuse-content'>{item.content}</p>
          <button
            type='button'
            className='view-more-button'
            onClick={() => this.openPreviewModal(item)}
          >
            Show more
          </button>
        </>
      )
    }
    if (row.id === 'when_posted') {
      const date = new Date(item.when_posted)
      if (typeof date.getMonth === 'function') {
        const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        const dateString = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })
        return (
          <>
            {`${dateString},`}
            <br />
            {timeString}
          </>
        )
      }
      return '-'
    }
    if (row.id === 'who_posted') {
      return (
        <>
          {item.who_posted}
          <br />
          {item.email}
        </>
      )
    }
    return item[row.id]
  }

  handleSearchChange = (val, name) => {
    const {
      setSearch,
      limit,
      skip,
    } = this.props
    setSearch({
      limit,
      skip,
      [name]: val,
    })
  }

  openConfirmModal = (item) => {
    this.setState({
      isConfirmModalOpen: true,
      selected: item,
    })
  }

  closeConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
      selected: {},
    })
  }

  openPreviewModal = (item) => {
    this.setState({
      isPreviewModalOpen: true,
      selected: item,
    })
  }

  closePreviewModal = () => {
    this.setState({
      isPreviewModalOpen: false,
      selected: {},
    })
  }

  handleDelete = async () => {
    const { removeData } = this.props
    const { selected: { entity_type, id } } = this.state // eslint-disable-line
    await removeData(entity_type, id)
    this.closeConfirmModal()
  }

  render() {
    const {
      dataList,
      total,
      skip,
      limit,
    } = this.props
    const { isConfirmModalOpen, isPreviewModalOpen, selected } = this.state
    return (
      <div className='abuses-list--page'>
        <Table className='abuses-table'>
          <TableHead>
            <TableRow>
              {this.renderTableHeaderRows()}
              <TableCell className='list-actions' />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map(n => (
              <TableRow key={n.id}>
                {rows.map(row => (
                  <TableCell
                    key={row.id}
                    className={row.id}
                  >
                    {this.cellRenderer(n, row)}
                  </TableCell>
                ))}
                <TableCell className='abuse-action'>
                  <button
                    type='button'
                    onClick={() => this.openConfirmModal(n)}
                  >
                    Delete message
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          handleChange={this.handleSearchChange}
          total={total}
          limit={limit}
          skip={skip}
        />
        <ConfirmDialog
          isConfirmModalOpen={isConfirmModalOpen}
          closeConfirmModal={this.closeConfirmModal}
          onSubmit={this.handleDelete}
          actionType='delete'
          name='abuse'
        />
        <Modal
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
          open={isPreviewModalOpen}
          onClose={this.closePreviewModal}
        >
          <div className='modal-content'>
            <p className='abuse-content'>{selected.content}</p>
            <button
              type='button'
              className='modal-button'
              onClick={this.closePreviewModal}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default AbuseList
