import React from 'react'
import AbuseContextWrapper, { AbuseContext } from '../../context/abuseContext'
import AbuseList from './AbuseList'


const Articles = () => (
  <AbuseContextWrapper>
    <AbuseContext.Consumer>
      {props => <AbuseList {...props} />}
    </AbuseContext.Consumer>
  </AbuseContextWrapper>
)

export default Articles
